html, body {
  width: 100%;
  height: 100%;
}

body {
  color: #000;
  background-color: #fff;
  margin: 0;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  overflow: hidden;
}

body.mfNoScroll {
  overflow: hidden;
}

.mf-bold {
  font-weight: bold;
}

.mf-italic {
  font-style: italic;
}

.mf-inline-graphic {
  white-space: nowrap;
}

.mf-copyright-line {
  margin-top: 12px;
  margin-bottom: 5px;
  font-size: smaller;
}

.mf-version {
  color: #666;
  font-style: italic;
  line-height: 1.7em;
}

.mf-name-in-text {
  font-weight: bold;
}

.mf-name-MAP, .mf-name-map {
  letter-spacing: -.1em;
}

.mf-name-frappe {
  font-family: Comic Sans, Comic Sans MS, Chalkboard, ChalkboardSE-Regular, Marker Felt, Purisa, URW Chancery L, Droid Sans Bold, Droid Sans, cursive;
}

.mf-error-response {
  margin: 0 11px;
}

#mf-mgr {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

#mf-mgr > div {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

#mf-occlude {
  z-index: 0;
  background-color: #fff;
}

#mf-mgr > #mf-map1 {
  z-index: -1;
}

#mf-mgr > #mf-map2 {
  z-index: -2;
}

.mf-dialog {
  max-height: 95vh;
}

.mf-dialog-body {
  overflow-y: scroll;
}

.mf-dialog h2 {
  font-size: 17px;
}

#mf-outer {
  z-index: 0;
  grid-template-rows: 38px 1fr;
  grid-template-columns: 1fr;
  place-content: stretch;
  gap: 0;
  width: 100%;
  height: 100%;
  display: grid;
}

.mf-banner {
  grid-area: 1 / 1 / 2 / 2;
  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr max-content;
  margin-bottom: 3px;
  display: grid;
}

.mf-banner > .mf-banner-area {
  grid-row: 1 / 2;
}

.mf-banner-controls {
  grid-column: 1 / 2;
  justify-self: start;
}

.mf-banner-controls > button, .mf-banner-ops > button {
  height: 100%;
  margin: 3px;
}

.mf-banner-logo {
  grid-column: 2 / 3;
  justify-content: center;
  justify-self: stretch;
  display: flex;
}

.mf-banner-ops {
  grid-column: 3 / 4;
  justify-self: end;
}

.mf-banner-ops > .mf-banner-ops-item {
  place-self: center stretch;
}

.mf-banner-logo-container {
  height: 100%;
  position: relative;
}

.mf-banner-logo-container .mf-app-name {
  z-index: 3;
  font-size: 24px;
  font-weight: bold;
  position: relative;
}

.mf-banner-logo-container .logo-page-name {
  z-index: 2;
  color: #00f;
  font-size: 10px;
  position: absolute;
  bottom: -3px;
  left: 0;
  overflow: visible;
}

.mf-banner-logo-container button.mf-page-chooser {
  z-index: 4;
  margin-left: 6px;
  padding: 0;
}

.mf-main {
  z-index: 1;
  grid-area: 2 / 1 / 3 / 2;
  position: relative;
}

.mf-map-active {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  cursor: grab;
}

.leaflet-container.mf-clickable {
  cursor: crosshair;
}

.leaflet-container.mf-clickable.mf-map-moving {
  cursor: grab;
}

.mf-map-parking {
  width: 100%;
  height: 100%;
  position: relative;
}

.mf-marker {
  background-color: #0000;
  border: none;
}

#mf-sxs-container {
  gap: 3px;
  width: 100%;
  height: 100%;
  margin: 3px;
  display: grid;
}

#mf-sxs-container.mf-horiz {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

#mf-sxs-container.mf-vert {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
}

.mf-sxs-pane {
  position: relative;
}

.mf-sxs-0 {
  grid-area: 1 / 1 / 1 / 1;
}

#mf-sxs-container.mf-horiz > .mf-sxs-1 {
  grid-area: 1 / 2 / 1 / 2;
}

#mf-sxs-container.mf-vert > .mf-sxs-1 {
  grid-area: 2 / 1 / 2 / 1;
}

.mf-search-container {
  z-index: 4;
  width: 290px;
  height: 1px;
  margin: 5px 0 0 5px;
  position: absolute;
}

.mf-search-container input {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mf-search-status {
  z-index: 6;
  background-color: #eee;
  border: 2px solid #000;
  width: 100%;
  max-height: 180px;
  padding-left: .5em;
  position: absolute;
  top: 25px;
  left: 9px;
  overflow-y: scroll;
}

.mf-search-status p.search-matched {
  cursor: default;
  border: 2px solid #0000;
  margin: 2px;
  padding: 2px;
  position: relative;
}

.mf-search-status p.search-matched:nth-child(2n) {
  background-color: #d8d8d8;
}

.mf-search-status p.search-matched:hover {
  border-color: #000c;
}

.mf-search-status p.search-matched:hover:after {
  content: "Click to go";
  color: #000;
  background-color: #c6eec5;
  border: 1px solid #000;
  border-radius: 6px;
  margin: 1px;
  padding: 3px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.mf-info-section .mf-info-section-body {
  margin: 16px;
}

.mf-site-info-dialog .sxs-pane-name {
  font-style: italic;
}

.mf-outlines-dragging-overlay {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mf-outlines-dragging-overlay polyline {
  fill: none;
  stroke: #22f;
  stroke-opacity: .5;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

#persistButton {
  margin: 5px 0 11px 2em;
}

.mf-outlines-info-dialog .sxs-pane-name {
  font-style: italic;
}

.mf-outlines-dialog-share p {
  line-height: 1.3;
}

.mf-outlines-result-url .mf-pending {
  color: #888;
  margin-left: 2em;
  font-style: italic;
}

.mf-copy-result-popover {
  max-width: 200px;
  padding: .5em;
  font-size: smaller;
}

.mf-persist-link {
  color: #215db0;
  font-family: Courier New, Courier, monospace;
  font-size: 13px;
  font-weight: bold;
}

.mf-outlines-result-url .mf-persist-link {
  margin-left: 2em;
}

.mf-copyable-link .mf-copy-link-icon {
  margin-left: .5em;
}

.mf-isoll-body {
  margin: 11px;
}
/*# sourceMappingURL=style.8a955830.css.map */
