html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  /* font-size: 12px; -- leave this for Blueprint */
  /* margin: 0px 6px 4px 6px; */
  margin: 0;
  color: #000;
  background-color: #FFF;

  /* body.mfNoScroll */
  overflow: hidden;
}

body.mfNoScroll {
  overflow: hidden;
}

.mf-bold {
  font-weight: bold;
}

.mf-italic {
  font-style: italic;
}

.mf-inline-graphic {
  white-space: nowrap;
}

.mf-copyright-line {
  margin-top: 12px;
  margin-bottom: 5px;
  font-size: smaller;
}

.mf-version {
  font-style: italic;
  color: #666;
  line-height: 1.7em;
}

.mf-name-in-text {
  font-weight: bold;
}

.mf-name-MAP,
.mf-name-map {
  letter-spacing: -0.1em;
}

/* Android doesn't have Comic Sans. Got this fallback sequence from the web. Droid Sans not so great on Android. */
.mf-name-frappe {
  font-family: 'Comic Sans', 'Comic Sans MS', 'Chalkboard', 'ChalkboardSE-Regular', 'Marker Felt', 'Purisa', 'URW Chancery L', 'Droid Sans Bold', 'Droid Sans', cursive;
}

.mf-error-response {
  margin: 0 11px;
}

/* ===========  map manager  =========== */

#mf-mgr {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#mf-mgr>div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

#mf-occlude {
  z-index: 0;
  background-color: #fff;
}

#mf-mgr>#mf-map1 {
  z-index: -1
}

#mf-mgr>#mf-map2 {
  z-index: -2;
}

/* ===========  global dialog  =========== */

.mf-dialog {
  max-height: 95vh;
}

.mf-dialog-body {
  overflow-y: scroll;
}

.mf-dialog h2 {
  font-size: 17px;
}

/* ===========  app outer containers  =========== */

#mf-outer {
  z-index: 0;
  width: 100%;
  height: 100%;
}

#mf-outer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 38px 1fr;
  /* use min-content? */
  column-gap: 0;
  row-gap: 0;
  justify-content: stretch;
  align-content: stretch;
}

/* ===========  banner  =========== */

.mf-banner {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: 1fr;
  margin-bottom: 3px;
}

.mf-banner>.mf-banner-area {
  grid-row: 1 / 2;
}

.mf-banner-controls {
  grid-column: 1 / 2;
  justify-self: start;
}

.mf-banner-controls>button,
.mf-banner-ops>button {
  /* display: inline-block; */
  height: 100%;
  margin: 3px;
}

.mf-banner-logo {
  grid-column: 2 / 3;
  justify-self: stretch;
  display: flex;
  /* center logo within this element - https: //stackoverflow.com/questions/114543 */
  justify-content: center;
}

.mf-banner-ops {
  grid-column: 3 / 4;
  justify-self: end;
}

.mf-banner-ops>.mf-banner-ops-item {
  align-self: center;
  justify-self: stretch;
}

/* ===========  logo within banner  =========== */

.mf-banner-logo-container {
  height: 100%;
  position: relative;
}

.mf-banner-logo-container .mf-app-name {
  position: relative;
  z-index: 3;
  font-size: 24px;
  font-weight: bold;
}

.mf-banner-logo-container .logo-page-name {
  position: absolute;
  z-index: 2;
  bottom: -3px;
  left: 0;
  overflow: visible;
  font-size: 10px;
  color: blue;
}

.mf-banner-logo-container button.mf-page-chooser {
  z-index: 4;
  margin-left: 6px;
  padding: 0;
}

/* ===========  main functional area  =========== */

.mf-main {
  /* assign z-index so that popups from the banner can go over maps easily */
  position: relative;
  z-index: 1;

  /* bottom portion of the window */
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.mf-map-active {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.leaflet-container {
  cursor: grab;
}

.leaflet-container.mf-clickable {
  cursor: crosshair;
}

.leaflet-container.mf-clickable.mf-map-moving {
  cursor: grab;
}

/* This is the direct parent of a Bing Map. The Bing Map is positioned "absolute,"
/* so this parent must be "positioned" - i.e. any 'position' attribute that's not "static." */
.mf-map-parking {
  /* so the element is "positioned" */
  position: relative;
  width: 100%;
  height: 100%;
}

.mf-marker {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

#mf-sxs-container {
  width: 100%;
  height: 100%;
  margin: 3px;
  display: grid;
  gap: 3px;
}

#mf-sxs-container.mf-horiz {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

#mf-sxs-container.mf-vert {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.mf-sxs-pane {
  position: relative;
}

.mf-sxs-0 {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

#mf-sxs-container.mf-horiz>.mf-sxs-1 {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
}

#mf-sxs-container.mf-vert>.mf-sxs-1 {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
}

/* ===========  search box  =========== */

.mf-search-container {
  position: absolute;
  z-index: 4;
  width: 290px;
  height: 1px;
  margin: 5px 0px 0px 5px;
}

.mf-search-container input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.mf-search-status {
  /* display: none; */
  z-index: 6;
  position: absolute;
  top: 25px;
  left: 9px;
  width: 100%;
  border: solid black 2px;
  background-color: #eee;
  padding-left: 0.5em;
  max-height: 180px;
  overflow-y: scroll;
}

/* .mf-search-status.status-focus,
.mf-search-status.status-typing,
.mf-search-status.status-searching,
.mf-search-status.status-display-choices {
  display: block;
  cursor: default;
} */

/* .mf-search-status.status-typing:not(.status-focus) {
  display: none;
} */

/* -- TODO --
.mf-search-status.status-searching {
  cursor: wait;
} */

/* -- TODO --
.mf-search-status.status-search-error p:first-child {
  color: red;
  font-style: italic;
} */

.mf-search-status p.search-matched {
  position: relative;
  /* sets up a new stacking context so that hover prompt works */
  margin: 2px;
  border: 2px solid rgba(0, 0, 0, 0);
  padding: 2px;
  cursor: default;
}

.mf-search-status p.search-matched:nth-child(even) {
  background-color: #d8d8d8;
}

.mf-search-status p.search-matched:hover {
  border-color: rgba(0, 0, 0, 0.8)
}

.mf-search-status p.search-matched:hover::after {
  content: "Click to go";
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  margin: 1px;
  padding: 3px;
  color: black;
  background-color: #c6eec5;
  border: 1px solid black;
  border-radius: 6px;
}

/* ===========  SiteInfoDialog  =========== */

.mf-info-section .mf-info-section-body {
  margin: 16px;
}

.mf-site-info-dialog .sxs-pane-name {
  font-style: italic;
}

/* ===========  outlines  =========== */

/* mostly the same styling ... and intent ... as '.mf-map-active' */
.mf-outlines-dragging-overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* polyline attributes manually cloned from Leaflet defaults */
.mf-outlines-dragging-overlay polyline {
  fill: none;
  stroke: #2222ff;
  stroke-opacity: 0.5;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

#persistButton {
  margin: 5px 0 11px 2em;
}

.mf-outlines-info-dialog .sxs-pane-name {
  font-style: italic;
}

.mf-outlines-dialog-share p {
  line-height: 1.3;
}

.mf-outlines-result-url .mf-pending {
  margin-left: 2em;
  color: #888;
  font-style: italic;
}

.mf-copy-result-popover {
  max-width: 200px;
  padding: 0.5em;
  font-size: smaller;
}

.mf-persist-link {
  color: #215db0;
  font-family: Courier New, Courier, monospace;
  font-size: 13px;
  font-weight: bold;
}

.mf-outlines-result-url .mf-persist-link {
  margin-left: 2em;
}

.mf-copyable-link .mf-copy-link-icon {
  margin-left: 0.5em;
}

/* ===========  iso-lat/lon  =========== */

.mf-isoll-body {
  margin: 11px;
}